import React from "react";
import { graphql } from "gatsby";
import { sbEditable } from "@storyblok/storyblok-editable";
import useStoryblok from "../components/storyblok";
import NotFoundPage from "@/pages/404";
import { CONTENT_TYPE_COMPONENT_MAP } from "@/utils/mapping";

export default function CMSEntry(prop) {
  const { pageContext } = prop;
  let story = pageContext.story;
  story = useStoryblok(story);
  const componentType = story.content?.component

  if (typeof CONTENT_TYPE_COMPONENT_MAP[componentType] !== "undefined") {
    const Component = CONTENT_TYPE_COMPONENT_MAP[componentType];
    return (
      <div {...sbEditable(story.content)}>
        <Component data={story} />
      </div>
    );
  } else {
    return <NotFoundPage />;
  }
}

export const query = graphql`
  query ($tag_list: [String], $full_slug: String, $field_component: String) {
    allStoryblokEntry(
      filter: {
        tag_list: { in: $tag_list }
        full_slug: { eq: $full_slug }
        field_component: { eq: $field_component }
      }
      sort: {fields: published_at, order: DESC}
    ) {
      nodes {
        id
        field_component
        slug
        full_slug
        content
        name
        tag_list
        published_at(formatString: "YYYY-MM-DD HH:mm:ss")
      }
    }
  }
`;
